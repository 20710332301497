import Api from '@/services/Api'

export default {
  findAll (pagination, idEmpresaCliente) {
    const param = {
      idEmpresaCliente, pagination
    }
    return Api().post('itens', param)
  },
  insert (item) {
    return Api().post('item', item)
  },
  update (item) {
    return Api().put('item', item)
  },
  delete (item) {
    return Api().delete(`item?id=${item.id}`, item)
  },
  findById (item) {
    const url = `item/${item.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().post('itensParam', param)
  },
  findByParamAutoComplete (param, idEmpresaCliente) {
    const texto = encodeURI(param)
    return Api().get('itensParamAutoComplete?idEmpresaCliente=' + idEmpresaCliente + '&param=' + texto)
  }
}
